@font-face {
  font-family: 'NewZen';
  src: local('NewZen'), url('./assets/fonts/newzen/New Zen Regular.otf') format('opentype');
}

@font-face {
  font-family: 'NewZen';
  font-weight: 900;
  src: local('NewZen'), url('./assets/fonts/newzen/New Zen Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NewZen';
  font-weight: 600;
  src: local('NewZen'), url('./assets/fonts/newzen/New Zen SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'NewZen';
  font-weight: 400;
  src: local('NewZen'), url('./assets/fonts/newzen/New Zen Light.otf') format('opentype');
}

@font-face {
  font-family: 'NewZen';
  font-weight: 200;
  src: local('NewZen'), url('./assets/fonts/newzen/New Zen Thin.otf') format('opentype');
}

* {
  font-family: 'NewZen', "New Zen", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: initial;
}

input:focus {
  outline: none;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.p-16 {
  padding: 4rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.items-center {
  align-items: center;
}

.justify-items {
  justify-items: center;
}

.bg-primary {
  background-color: #FFA74F;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.font-light {
  font-weight: 400;
}

.font-extralight {
  font-weight: 200;
}

.text-center {
  text-align: center;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.flex-none {
  flex: none;
}

.w-64 {
  width: 8rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-32 {
  gap: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.h-36 {
  height: 9rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.33%;
}

.w-2\/3 {
  width: 66.66%;
}

.w-5\/12 {
  width: 41.66%;
}

.w-7\/12 {
  width: 58.33%;
}

.w-2\/5 {
  width: 40%;
}

.w-4\/5 {
  width: 80%;
}

.bg-secondary {
  background-color: #164E90;
}

.bg-white {
  background-color: #FBF9EA;
}

.text-white {
  color: #FFFFFF;
}

.text-white-light {
  color: #FBF9EA;
}

.text-dark {
  color: #5C5C5C;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.hidden {
  display: none;
}

.rounded {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 1rem;
}

.border-none {
  border: none;
}

.w-full {
  width: 100%;
}

.w-96 {
  width: 24rem;
}

.w-80 {
  width: 20rem;
}

.w-72 {
  width: 18rem;
}

.w-64 {
  width: 16rem;
}

.w-56 {
  width: 14rem;
}

.w-48 {
  width: 12rem;
}

.w-40 {
  width: 10rem;
}

.w-36 {
  width: 9rem;
}

.w-32 {
  width: 8rem;
}

.w-24 {
  width: 6rem;
}

.w-20 {
  width: 5rem;
}

.w-16 {
  width: 4rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.max-w-64 {
  max-width: 16rem;
}

.max-w-72 {
  max-width: 18rem;
}

.max-w-80 {
  max-width: 20rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-md {
  font-size: 0.95rem;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 1.5vh;
}

.bottom-1 {
  bottom: 4vh;
}

.left-2 {
  left: 22vw;
}

.left-5 {
  left: 29vw;
}

.overflow-hidden {
  overflow: hidden;
}

.object-contain {
  object-fit: contain;
}

svg {
  width: 100%;
}


@media (min-width: 1571px) {
  .md\:px-24 {
    padding-left: 25vw !important;
    padding-right: 25vw !important;
  }

  .bottom-0 {
    bottom: 1.25vh !important;
  }

  .bottom-1 {
    bottom: 3vh !important;
  }

  .left-2 {
    left: 33.5vw !important;
  }

  .left-5 {
    left: 37.5vw !important;
  }
}

@media (min-width: 789px) {
  .md\:block {
    display: block !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:ml-64 {
    margin-left: 30vw;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-end {
    justify-content: end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-items {
    justify-items: center;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.33%;
  }

  .md\:w-2\/3 {
    width: 66.66%;
  }

  .md\:w-5\/12 {
    width: 41.66%;
  }

  .md\:w-7\/12 {
    width: 58.33%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:max-w-64 {
    max-width: 16rem;
  }

  .md\:max-w-72 {
    max-width: 18rem;
  }

  .md\:max-w-80 {
    max-width: 20rem;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }


  .md\:-mt-16 {
    margin-top: -4rem;
  }
}

@media (max-width: 789px) {
  .xs\:justify-between {
    justify-content: space-between;
  }

  .xs\:absolute {
    position: absolute;
  }

  .xs\:inset-0 {
    left: 0;
    right: 0;
    bottom: 0;
  }

  .xs\:w-full {
    width: 100%;
  }

  .xs\:bg-white {
    background-color: #FBF9EA;
  }

  .xs\:h-\[90vh\] {
    min-height: 90vh;
  }

  .xs\:top-3 {
    top: 5rem;
  }

  .xs\:items-center {
    justify-items: center;
  }

  .xs\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xs\:overflow-hidden {
    overflow: hidden;
  }
}

.bg-transparent {
  background: transparent !important;
}

.no-break {
  word-break: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 1.25rem;
}
